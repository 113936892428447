<template>
  <main>
    <div class="page-header">
      <div class="container-fluid">
        <breadcrumbs></breadcrumbs>
        <div class="row">
          <div class="col-sm-9">
            <h1 class="page-title">Create Package</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-9">

          <!-- Create package form -->
          <form>
            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">Who do you want to submit to?</label>
              <div class="col-sm-8">
                <div class="radio-btn-group">
                  <div class="radio-btn">
                    <input id="radio1" name="radio" type="radio" class="radio" checked>
                    <label for="radio1">
                      Land Title
                    </label>
                  </div>
                  <div class="radio-btn">
                    <input id="radio2" name="radio" type="radio" class="radio" disabled>
                    <label for="radio2">
                      Surveyor General
                    </label>
                  </div>
                </div>
                <p class="form-text text-muted"><i>Submitting to Surveyor General will be available in future releases</i></p>
              </div>
            </div>
            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">File Reference</label>
              <div class="col-sm-8">
                <input type="text" class="form-control" id="name">
                <p class="form-text">Limit 20 characters</p>
              </div>
            </div>
            <div class="form-group row">
              <label for="name" class="col-sm-4 col-form-label">Package description (optional)</label>
              <div class="col-sm-8">
                <textarea type="text" class="form-control" id="name"></textarea>
                <p class="form-text">Limit 120 characters</p>
              </div>
            </div>
            <div class="form-group row">
              <div class="form-actions offset-sm-4 col-sm-8">
                <button type="submit" class="btn btn-primary">Create Package</button>
                <a href="#" class="btn btn-link">Cancel</a>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Scroll to top -->
      <a href="#top" class="d-block float-right d-print-none scroll-to-top">
        <svg class="icon icon-arrow_upward"><use xlink:href="/icons/symbol-defs.svg#icon-arrow_upward"></use></svg>Back to Top
      </a>
    </div>

    <help-panel title="Create Package"></help-panel>
  </main>
</template>

<script>

import HelpPanel from '../../components/HelpPanel.vue'

export default {

  components: {
    'help-panel': HelpPanel
  },

  data () {
    return {

    }
  }
}
</script>

